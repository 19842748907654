h1 {
  font-size: 18pt;
}

h2 {
  font-size: 16pt;
}

h3 {
  font-size: 14pt;
}

h4 {
  font-size: 12pt;
}

body {
  font-size: 10pt;
}

a:hover {
  color: #002147;
  text-decoration: underline;
}

a {
  color: #014493;
  text-decoration: none;
}

h1,
h2,
h3 {
  line-height: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: inherit;
  font-weight: bold;
  color: inherit;
}

* {
  box-sizing: border-box;
}

#left_pane {
  min-height: 100vh;
  padding: 50px 20px 20px 20px;
  flex: 0 0 auto;
  background-color: #f5f5f5;
  position: relative;
}

#left_pane_resizer {
  background-color: #f0f0f0;
  cursor: grab;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
  z-index: 11;
}

#main_pane {
  padding: 50px 20px 20px 20px;
  flex: 1 1 auto;
  background-color: white;
  z-index: 10;
}

ol {
  padding-bottom: 5px;
}

li > p {
  margin: 0;
}

li {
  margin: 5px 0 5px 0;
}

/* Viz Styling... */
.node {
  font-family: Arial;
}

.lowkey {
  color: #aaa;
}

.error {
  color: red;
  font-weight: bold;
}

/*
.flex_item      { flex: 1 1 50%; overflow-x: hidden; }

@media (max-width: 979px) {
    .flex_item_desktop  { flex: 0 0 0%; display: none; }
}
*/

div.match_result {
  border: 1px solid #ccc;
  margin: 0 0 10px 0;
  border-radius: 5px;
  padding: 10px;
}

div.match_result:hover {
  background-color: #eee;
  cursor: pointer;
}

div.match_result > span {
  margin-left: 40px;
  color: #ccc;
  float: right;
}

div.match_result .highlight {
  background-color: yellow;
}

.divider {
  padding: 5px;
}

.message {
  margin: 50px;
  font-weight: bold;
  color: red;
  font-size: 24px;
}

.gen_block {
  margin: 20px 0 20px 0;
}

@media print {
  body {
    font-family: Arial;
  }

  nav {
    display: none;
  }

  ul#curr_location {
    display: none;
  }
}

/* From Bootstrap... */

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 10pt;
  line-height: 20px;
  color: #535e68;
  background-color: #ffffff;
}

/* Navbar Layout */

.navbar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  z-index: 12;
}

.navbar > div {
  margin-left: 10px;
}

.navbar-search {
  flex-grow: 2;
}

.navbar-search input {
  margin: 0;
  padding: 4px 14px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  border-radius: 15px;
  border: 1px solid #e6e6e6;
  display: inline-block;
  height: 30px;
  color: #555555;
  width: calc(100% - 30px);
}

.navbar-search input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  /* width: 250px; */
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(82, 168, 236, 0.6);
}

.navbar-info {
  font-size: 20px;
  background-color: #f5f5f5;
}

.navbar-info a {
  color: rgb(0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: 3px;
  font-weight: bold;
}

#curr_location {
  padding: 0;
  list-style-type: none;
  margin: 0;
  white-space: nowrap;
}

#curr_location li {
  display: inline;
}

.navbar-buttons {
  right: 0;
  padding: 14px 5px;
  background-color: #f5f5f5;
}

.btn-group {
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
}

.navbar .btn-group .btn {
  margin-top: 0;
}

.btn-group > .btn.active {
  z-index: 2;
}

.btn-group > .btn:first-child {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-group > .btn:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn-group > .btn-mini {
  font-size: 7.5pt;
}

.btn-group > .btn {
  position: relative;
  border-radius: 0;
  background-color: #fff;
}

.btn-group > .btn + .btn {
  margin-left: -1px;
}

.btn.active {
  background-image: none;
  outline: 0;
  color: #333333;
  background-color: #e6e6e6;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-mini {
  padding: 0 6px;
}

.btn:hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  background-color: #e6e6e6;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid #cccccc;
  border-bottom-color: #b3b3b3;
}

/*
@media (max-width: 979px) {
    body {
        padding-top: 0;
    }
}

@media (max-width: 767px) {
    body {
        padding-right: 20px;
        padding-left: 20px;
    }
}
*/
